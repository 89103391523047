var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"communicationEffect"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("推送数据概览")]),_c('ul',{staticClass:"push-data"},[_c('li',[_c('p',{staticClass:"data data1",domProps:{"textContent":_vm._s(_vm.pushInfoData.countPush || 0)}}),_c('p',{staticClass:"text"},[_vm._v("推送总次数")])]),_c('li',[_c('p',{staticClass:"data data2",domProps:{"textContent":_vm._s(_vm.pushInfoData.countOpenAuthor || 0)}}),_c('p',{staticClass:"text"},[_vm._v("阅读邮件用户总量")])]),_c('li',[_c('p',{staticClass:"data data3",domProps:{"textContent":_vm._s(_vm.pushInfoData.countReadAuthor || 0)}}),_c('p',{staticClass:"text"},[_vm._v("阅读文章用户总量")])])]),_c('div',{staticClass:"first-title mt-20 mb-20 fl"},[_vm._v("邮件推送列表")]),_c('commonTable',{ref:"taskList",class:_vm.taskTableStyle,attrs:{"list-text":"任务列表","table-data":_vm.taskTableData,"column-data":_vm.taskColumn,"init-obj":_vm.taskPageObj,"show-list-btn":"","no-serial":"","show-choose":"","is-click":""},on:{"onRowClick":_vm.handleRowClick,"onChangePageData":(pageObj) => {
        _vm.getTaskList(pageObj)
      }}}),_c('div',{staticClass:"article-reading mb-20 mt-20"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("用户活跃度排行")]),_c('commonTable',{attrs:{"table-data":_vm.authorTableData,"column-data":_vm.authorColumn,"init-obj":_vm.authorPageObj,"is-click":false},on:{"onChangePageData":(pageObj) => {
          _vm.getAuthorTableData(pageObj)
        },"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order)
        }}})],1),_c('div',{staticClass:"flex-content mt-20"},[_c('div',{staticClass:"left-content"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("阅读邮件用户来源机构")]),_c('commonTable',{attrs:{"table-data":_vm.YDYJYHLYJGTableData,"column-data":_vm.YDYJYHLYJGColumnData,"serial-text":"排名","no-pagination":"","align-text":"left"}})],1),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("阅读邮件用户来源期刊")]),_c('commonTable',{attrs:{"table-data":_vm.YDYJYHLYQKTableData,"column-data":_vm.YDYJYHLYQKColumnData,"serial-text":"排名","no-pagination":"","align-text":"left"}})],1)]),_c('div',{staticClass:"flex-content mt-20"},[_c('div',{staticClass:"left-content"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("阅读邮件用户地域分布-国内")]),_c('commonTable',{attrs:{"table-data":_vm.chinaTableData,"column-data":_vm.chinaColumnData,"no-pagination":"","align-text":"left"}})],1),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("阅读邮件用户地域分布-海外")]),_c('commonTable',{attrs:{"table-data":_vm.worldTableData,"column-data":_vm.worldColumnData,"no-pagination":"","align-text":"left"}})],1)]),_c('div',{staticClass:"article-reading mb-20 mt-20"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("文章阅读量排行")]),_c('commonTable',{attrs:{"no-pagination":"","table-data":_vm.CBXGWZYDLTableData,"column-data":_vm.CBXGWZYDLColumnData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }