<template>
  <div v-loading="loading" class="communicationEffect">
    <!-- 推送详情 -->
    <div class="first-title mb-20">推送数据概览</div>
    <ul class="push-data">
      <li>
        <p class="data data1" v-text="pushInfoData.countPush || 0" />
        <p class="text">推送总次数</p>
      </li>
      <li>
        <p class="data data2" v-text="pushInfoData.countOpenAuthor || 0" />
        <p class="text">阅读邮件用户总量</p>
      </li>
      <li>
        <p class="data data3" v-text="pushInfoData.countReadAuthor || 0" />
        <p class="text">阅读文章用户总量</p>
      </li>
    </ul>
    <!-- 任务列表 -->
    <div class="first-title mt-20 mb-20 fl">邮件推送列表</div>
    <commonTable
      ref="taskList"
      list-text="任务列表"
      :class="taskTableStyle"
      :table-data="taskTableData"
      :column-data="taskColumn"
      :init-obj="taskPageObj"
      show-list-btn
      no-serial
      show-choose
      is-click
      @onRowClick="handleRowClick"
      @onChangePageData="
        (pageObj) => {
          getTaskList(pageObj)
        }
      "
    />
    <!-- 用户活跃度排行 -->
    <div class="article-reading mb-20 mt-20">
      <div class="first-title mb-20">用户活跃度排行</div>
      <!-- table表格 -->
      <commonTable
        :table-data="authorTableData"
        :column-data="authorColumn"
        :init-obj="authorPageObj"
        :is-click="false"
        @onChangePageData="
          (pageObj) => {
            getAuthorTableData(pageObj)
          }
        "
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order)
          }
        "
      />
    </div>
    <div class="flex-content mt-20">
      <div class="left-content">
        <div class="first-title mb-20">阅读邮件用户来源机构</div>
        <commonTable
          :table-data="YDYJYHLYJGTableData"
          :column-data="YDYJYHLYJGColumnData"
          serial-text="排名"
          no-pagination
          align-text="left"
        />
      </div>
      <div class="right-content">
        <div class="first-title mb-20">阅读邮件用户来源期刊</div>
        <commonTable
          :table-data="YDYJYHLYQKTableData"
          :column-data="YDYJYHLYQKColumnData"
          serial-text="排名"
          no-pagination
          align-text="left"
        />
      </div>
    </div>
    <div class="flex-content mt-20">
      <div class="left-content">
        <div class="first-title mb-20">阅读邮件用户地域分布-国内</div>
        <commonTable
          :table-data="chinaTableData"
          :column-data="chinaColumnData"
          no-pagination
          align-text="left"
        />
      </div>
      <div class="right-content">
        <div class="first-title mb-20">阅读邮件用户地域分布-海外</div>
        <commonTable
          :table-data="worldTableData"
          :column-data="worldColumnData"
          no-pagination
          align-text="left"
        />
      </div>
    </div>
    <div class="article-reading mb-20 mt-20">
      <div class="first-title mb-20">文章阅读量排行</div>
      <commonTable
        no-pagination
        :table-data="CBXGWZYDLTableData"
        :column-data="CBXGWZYDLColumnData"
      />
    </div>
  </div>
</template>

<script>
import { Get } from 'kjqk-commons/src/common/index.js'
import commonTable from '@comp/commonTable'
import { mapState } from 'vuex'
export default {
  name: 'CommunicationEffect',
  components: {
    commonTable
  },
  data() {
    return {
      loading: true,
      pushInfoData: {
        countReadAuthor: '',
        countPush: '',
        countOpenAuthor: ''
      },
      taskId: '',
      authorTableData: [],
      authorColumn: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true
        },
        {
          title: '研究领域',
          prop: 'studyFieldDetail',
          notSortable: true
        },
        {
          title: '阅读文章次数',
          prop: 'countRead'
        },
        {
          title: '阅读邮件次数',
          prop: 'countOpen'
        }
      ],
      authorPageObj: {
        page: 1,
        size: 10
      },
      GWFWdata: [],
      WZYDQKdata: [],
      leftPushlishTime: '',
      rightPushlishTime: '',
      taskPageObj: {
        page: 1,
        size: 10
      },
      taskTableData: null, // 推送任务列表
      taskColumn: [
        {
          title: '任务标题',
          prop: 'taskTopic',
          notSortable: true
        },
        {
          title: '推送时间',
          prop: 'pushTime',
          notSortable: true
        },
        {
          title: '邮件推送数量',
          prop: 'pushSuccessCount',
          notSortable: true
        },
        {
          title: '邮件阅读次数',
          prop: 'emailOPenCount',
          notSortable: true
        },
        {
          title: '阅读邮件用户量',
          prop: 'onlyEmailOPenCount',
          notSortable: true
        },
        {
          title: '文章阅读次数（邮件）',
          prop: 'readArticleCount',
          notSortable: true
        },
        {
          title: '阅读文章用户量',
          prop: 'onlyReadArticleCount',
          notSortable: true
        }
      ],
      currentTask: '',
      networkStartVisitLegend: false,
      startVisitLegend: false,
      endVisitLegend: false,
      YDYJYHLYJGTableData: [],
      YDYJYHLYJGColumnData: [
        {
          title: '机构',
          prop: 'orgName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'countOrg',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      YDYJYHLYQKTableData: [],
      YDYJYHLYQKColumnData: [
        {
          title: '期刊',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'countMag',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      chinaTableData: [],
      chinaColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'countAuthor',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'proportion',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      worldTableData: [],
      worldColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'countAuthor',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'proportion',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      CBXGWZYDLTableData: [],
      CBXGWZYDLColumnData: [
        {
          title: '标题',
          prop: 'title',
          special: true,
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'orgs',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量（邮件）',
          prop: 'countArticle',
          width: '120px',
          notSortable: true
        }
      ]
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    taskTableStyle() {
      return this.taskTableData && this.taskTableData.length > 0
        ? 'task-list'
        : 'task-list-mt-20'
    },
    isDisabled() {
      return this.currentTask.year < 2021
    }
  },
  watch: {
    magId(n) {
      if (n) {
        this.$nextTick(() => {
          this.getPushInfoData()
          this.getTaskList()
        })
      } else {
        this.$nextTick(() => {
          this.initData()
        })
      }
    },
    currentTask() {
      this.$nextTick(() => {
        this.getData()
      })
    }
  },
  mounted() {
    if (this.magId) {
      this.$nextTick(() => {
        this.getPushInfoData()
        this.getTaskList()
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    initData() {
      this.pushInfoData = {
        countReadAuthor: '',
        countPush: '',
        countOpenAuthor: ''
      }
      this.taskTableData = []
      this.authorTableData = []
      this.YDYJYHLYJGTableData = []
      this.YDYJYHLYQKTableData = []
      this.YDYJYHLYQKTableData = []
      this.chinaTableData = []
      this.worldTableData = []
      this.CBXGWZYDLTableData = []
    },
    getData() {
      this.getAuthorTableData()
      this.getYDYJYHLYJGTableData()
      this.getYDYJYHLYQKTableData()
      this.getYDYJYHLYQKTableData()
      this.getAreaTableData(1)
      this.getAreaTableData(2)
      this.getCBXGWZYDLTableData()
    },
    /* 推送数据概览 */
    getPushInfoData() {
      let params = {
        magId: this.magId
      }
      let url = '/das-api/vas/push/citation/getCitationReport/statistic'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.pushInfoData = res.data.data
        }
      })
    },
    // 推送任务列表
    getTaskList(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId,
        statusList: 3
      }
      let url = '/das-api/vas/push/citation/getPage'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.taskPageObj = {
            page,
            size,
            total: totalElements
          }
          this.taskTableData = content
          if (this.taskTableData && this.taskTableData.length > 0) {
            this.$nextTick(() => {
              this.$refs.taskList.handleRowClick(this.taskTableData[0])
            })
          }
        }
      })
    },
    // 点击每一行
    handleRowClick(id, item) {
      this.currentTask = item
      this.networkStartVisitLegend = false
      this.startVisitLegend = false
      this.endVisitLegend = false
    },
    /* 用户活跃度排行榜 */
    getAuthorTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.currentTask.id
      }
      let url =
        '/das-api/vas/push/citation/getCitationReport/authorActivityRanking'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.authorPageObj = {
            page,
            size,
            total: totalElements
          }
          this.authorTableData = content
        }
      })
    },
    // 排序的改变了
    handleChangeSort(prop, order) {
      this.sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          this.sortValue = prop + ',asc'
        } else {
          this.sortValue = prop + ',desc'
        }
      }
      this.getAuthorTableData()
    },
    /* 阅读邮件用户来源机构 */
    getYDYJYHLYJGTableData() {
      const params = {
        taskId: this.currentTask.id,
        page: 0,
        size: 10
      }
      let url =
        '/das-api/vas/push/citation/getCitationReport/openEmailAuthorOrg'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.YDYJYHLYJGTableData = data.content
        }
      })
    },
    /* 阅读邮件用户来源期刊 */
    getYDYJYHLYQKTableData() {
      const params = {
        taskId: this.currentTask.id,
        page: 0,
        size: 10
      }
      let url =
        '/das-api/vas/push/citation/getCitationReport/openEmailAuthorMag'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.YDYJYHLYQKTableData = data.content
        }
      })
    },
    /* 阅读邮件用户地域分布-国内-海外 */
    getAreaTableData(type) {
      const params = {
        taskId: this.currentTask.id,
        page: 0,
        size: 10,
        type
      }
      let url = '/das-api/vas/push/citation/getCitationReport/diffusePath'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (type === 1) {
            this.chinaTableData = data.content
            if (this.chinaTableData.length > 0) {
              this.chinaTableData.map((it) => {
                if (it && it.territory && it.territory.indexOf('中国-') != -1) {
                  it.territory = it.territory.split('中国-')[1]
                }
              })
            }
          }
          if (type === 2) {
            this.worldTableData = data.content
            this.worldTableData.map((it) => {
              if (it && it.territory && it.territory.indexOf('--') != -1) {
                it.territory = it.territory.split('--')[0]
              }
            })
          }
        }
      })
    },
    /* 文章阅读量排行 */
    getCBXGWZYDLTableData() {
      let params = {
        taskId: this.currentTask.id,
        page: 0,
        size: 10
      }
      let url =
        '/das-api/vas/push/citation/getCitationReport/articleReadRanking'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.CBXGWZYDLTableData = data.content
          if (this.CBXGWZYDLTableData.length > 0) {
            this.CBXGWZYDLTableData.map((item) => {
              item.pubYearStage =
                (item.year ? item.year + '年' : '') +
                (item.vol ? '第' + ~~item.vol + '卷' : '') +
                (item.issue ? '第' + ~~item.issue + '期' : '')
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">
.communicationEffect {
  .el-cascader {
    width: 260px;
  }
  .list-btn {
    height: 20px;
    line-height: 20px !important;
    margin-top: 20px;
  }
}
</style>
<style lang="scss" scoped>
.communicationEffect {
  padding: 20px;
  .push-data {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    color: #999;
    text-align: left;
    line-height: 30px;
    background: #f5f7fd;
    padding: 14px 0;
    margin: 20px 0 0 0;
    li {
      text-align: center;
      .data {
        line-height: 28px;
        font-size: 22px;
        margin: 0;
      }
      .data1 {
        color: #ec5463;
      }
      .data2 {
        color: #0ba9e1;
      }
      .data3 {
        color: #2db897;
      }
      .text {
        line-height: 24px;
        margin: 0;
      }
    }
  }
  .flex-content {
    display: flex;
    justify-content: space-between;
    .left-content,
    .right-content {
      width: 49%;
    }
  }
  .article-reading {
    .table-title {
      margin-top: 20px;
    }
    .conter_3 {
      margin: 0 !important;
    }
  }
}
</style>
